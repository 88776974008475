<template>
  <v-layout wrap>
    <v-flex lg4 md4 sm12 v-for="project in projects" :key="project.name" class="pa-2">

      <v-hover>
        <template v-slot:default="{ hover, props }">
          <v-card class="mx-auto image-container" flat hover tile target="_blank" :href="project.repoUrl">
            <v-img height="180" :src="project.logoUrl" v-bind="props" :class="{ 'image-darken': hover }" cover
              aspect-ratio="1">

              <v-overlay absolute>
                <v-list-item three-line class="overlay-text">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 mb-1">
                      {{ project.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-show="hover" class="text-subtitle-2 text--white">{{ project.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-overlay>
            </v-img>
          </v-card>
        </template>
      </v-hover>
    </v-flex>
  </v-layout>
</template>


<style scoped>
.image-container {
  position: relative;
}

.image-container::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* No darkening by default */
  transition: background-color 0.3s;
}

.image-container.hover::before {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the darkening effect here */
}

.v-overlay {
  transition: opacity 0.3s;
  opacity: 0;
}

.v-overlay--active {
  opacity: 1;
}

.overlay-text {
  color: white;
  /* Adjust the color as needed */
  /* Ensure the text has full brightness */
  filter: brightness(100%);
}
</style>

<script>

export default {
  data() {
    return {
      projects: [
        {
          name: 'AI image gallery',
          logoUrl: '/sdxl-2.jpg',
          description: 'A showcase app to generate images and display them with efficient loading.',
          repoUrl: 'https://github.com/meteron-ai/hazy-hues'
        },
        {
          name: 'Multi-tenant AI gen',
          logoUrl: '/ai-room.webp',
          description: 'An advanced Next.js app with auth, image generation and per-user limits.',
          repoUrl: 'https://github.com/meteron-ai/roomfix'
        },
        {
          name: 'Python API usage',
          logoUrl: '/background.png',
          description: 'Example app of sending image generation requests in vanilla javascript.',
          repoUrl: 'https://github.com/meteron-ai/python-example'
        }
      ]
    }
  }
}

</script>